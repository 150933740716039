import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import Dropzone from "react-dropzone";
import CustomDialog from "shared/components/CustomDialog";
import HelperText from "shared/components/HelperText";
import { UploadableCol } from "../..";

import { ValueSetterParams } from "ag-grid-community";
import { getDoctypes } from "services/api";
import { uploadFileApi } from "services/api/api.service";
import dropzoneStyles from "shared/components/UploadManager/styles.module.scss";
import { DROPZONE_ACCEPTED_FILE } from "shared/constants/general";
import { NAME } from "shared/constants/localStorage";
import { useLocalStorage, useProfile } from "shared/hooks";
import useRole from "shared/hooks/useRole";
import IFilesSchema from "shared/schemas/files.schema";
import { DocType, DocTypesRes } from "shared/types/DocType";
import logger from "utils/logger";
import {
  aboveZeroTest,
  lessThan,
  requiredStringSchema,
} from "utils/yupValidation";
import * as yup from "yup";
import { ValidationError } from "yup";
import CrmUploadForm from "./Form";
import { BidderRole, BrokerProfile, ClientProfile } from "shared/types/Profile";
import { toast } from "react-toastify";
export interface CrmForm {
  comment: string;
  amount: string;
  interest_rate: string;
  bank: string;
  consulting_fees: string;
}

type Props = {
  open: boolean;
  handleDataRef: MutableRefObject<{
    newData: any[];
    params: ValueSetterParams;
  } | null>;
  postCrmValues: (payload: {
    successMsg?: string | undefined;
    rejectMsg?: string | undefined;
    comment?: string | undefined;
    fileUrl?: string | undefined;
    fileType?: string | undefined;
    payload?: any;
  }) => void;
  openUploadDialog: () => void;
  closeUploadDialog: () => void;
  setTablesData: (resetToPreviousData?: boolean) => void;
};

const formValidation = (isNotaEncargo: boolean) => {
  return yup.object({
    // comment: string;
    amount: requiredStringSchema().test(aboveZeroTest).test(lessThan()),
    interest_rate: requiredStringSchema().test(aboveZeroTest),
    // bank?: string;
    bank: yup.string().when("dummy", {
      is() {
        return !isNotaEncargo;
      },
      then() {
        return requiredStringSchema();
      },
    }),
    consulting_fees: yup.string().nullable(),
  });
};
const parseDic = {
  FEIN: "Doc_fein",
  tasadoraRealizada: "Doc_tasadora",
  notaEncargoFirmada: "Doc_nota_encargo_firmada",
};

const formInitialState = {
  comment: "",
  amount: "",
  interest_rate: "",
  consulting_fees: "",
  bank: "",
};

/** put file col in the list that you want to be optional upload */
const optionalUploadByRole: Record<BidderRole, string[]> = {
  bank: ["tasadoraRealizada", "FEIN", "notaEncargoFirmada"],
  broker: ["tasadoraRealizada"],
};

export default function UploadDialog({
  open,
  handleDataRef,
  postCrmValues,
  closeUploadDialog,
  setTablesData,
}: Props) {
  const { isUserBank, role } = useRole();
  const [bankName] = useLocalStorage(NAME);
  const [form, setForm] = useState<CrmForm>({
    ...formInitialState,
    bank: isUserBank ? bankName : "",
  });
  const [formError, setFormError] = useState<CrmForm>(formInitialState);

  const coldId = handleDataRef.current?.params.column.getColId();
  const isNotaEncargo = coldId === "notaEncargoFirmada";
  const validateForm = formValidation(isNotaEncargo);

  const [file, setFile] = useState<IFilesSchema | null>(null);
  const docTypesRef = useRef<DocType[]>();

  const [uploading, setUploading] = useState<boolean>(false);
  /** col that are curr selecting, opening */
  const fileColName = handleDataRef.current?.params.colDef.field;
  const isFileOptionalToUpload = optionalUploadByRole[
    role as BidderRole
  ].includes(fileColName || "");
  const disabled = uploading || (!isFileOptionalToUpload && !Boolean(file));
  const { profile, isFetching } = useProfile<BrokerProfile>();
  const tasadoraGeneralFile = {
    type: "Doc_tasadora",
    url: "https://colibid.fra1.digitaloceanspaces.com/shared/appraisal_document.pdf",
  };

  useEffect(() => {
    if (isNotaEncargo && profile) {
      parseDic.notaEncargoFirmada = profile.order_note_code
        ? profile.order_note_code
        : parseDic.notaEncargoFirmada;
    }
  }, [profile]);

  const getFileType = (colId: UploadableCol): DocType => {
    const docType = docTypesRef.current?.find(
      (e) => e.code === parseDic[colId]
    );
    return docType!;
  };

  const acceptedFileType = () => {
    if (handleDataRef.current && docTypesRef.current) {
      const colId = handleDataRef.current?.params.colDef.field;
      const docType = getFileType(colId as UploadableCol);
      // if (docType.accepted_mime_types.length > 0) {
      //   const acceptedFiles: Record<any, []> = {};
      //   docType.accepted_mime_types.forEach((e) => {
      //     if (e.is_active) {
      //       acceptedFiles[e.mime_type] = [];
      //     }
      //   });
      //   return acceptedFiles;
      // }

      
      if (!docType) {
        return DROPZONE_ACCEPTED_FILE;
      }

      // Verificar si el tipo de documento es PDF
      if (
        docType.accepted_mime_types.some(
          (mime) => mime.mime_type === "application/pdf" && mime.is_active
        )
      ) {
        const acceptedFiles: Record<string, any[]> = {};
        acceptedFiles["application/pdf"] = [];
        return acceptedFiles;
      }
    }

    return DROPZONE_ACCEPTED_FILE;
  };
  const handleOnDrop = (afiles: any) => {
    setUploading(false);

    const newFile: IFilesSchema = {
      name: afiles[0].name,
      type: "",
      file: afiles[0],
      status: "await",
      error: false,
      error_message: "",
    };
    setFile(newFile);
  };

  const handleSubmit = (fileType: string = "", fileUrl: string = "") => {
    const { amount, interest_rate, bank, consulting_fees, comment } = form;
    postCrmValues({
      comment,
      successMsg: "La información del cliente se ha actualizado correctamente",
      fileType,
      fileUrl,
      payload: {
        amount,
        interest_rate,
        bank,
        consulting_fees,
      },
    });
  };

  const onClickUploadFile = async () => {
    try {
      //check form is valid
      if (isNotaEncargo && profile) {
        if (!profile.CIF_ICI_company) {
          toast.error("Se requiere el CIF_ICI_company.");
          return;
        }
      }

      if (coldId !== "tasadoraRealizada") {
        await validateForm.validate(form, {
          abortEarly: false,
        });
        setFormError(formInitialState);
      }

      if (file) {
        file["status"] = "loading";
        setFile(file);
        if (handleDataRef.current) {
          setUploading(true);
          const fileUrl = await uploadFileApi(file.file);

          const { params } = handleDataRef.current;
          const colId = params.column.getColId() as UploadableCol;

          const fileType = getFileType(colId).code;

          file["status"] = "loaded";
          setFile(file);

          handleSubmit(fileType, fileUrl);
          setFile(null);
        }
      } else {
        if (coldId === "tasadoraRealizada") {
          handleSubmit(tasadoraGeneralFile.type, tasadoraGeneralFile.url);
        } else {
          handleSubmit();
        }
      }
      setForm(formInitialState);
      closeUploadDialog();
    } catch (e: any) {
      logger.error({ e });
      if (e instanceof ValidationError) {
        e.inner.forEach((el) => {
          const { message, path } = el;
          if (path) {
            setFormError((prev) => ({
              ...prev,
              [path]: message,
            }));
          }
        });
        return;
      }
      logger.error("error catch upload file", e);
      setFile((oldFile) => {
        if (oldFile) {
          oldFile["status"] = "error";
          oldFile["error"] = true;
          oldFile["error_message"] = e;
        }
        return oldFile;
      });
    } finally {
      setUploading(false);
    }
  };

  const onClickUploadCancel = () => {
    setTablesData(true);
    setFile(null);
    setForm(formInitialState);
    setFormError(formInitialState);
    closeUploadDialog();
  };

  useEffect(() => {
    getDoctypes().then((res: { data: DocTypesRes }) => {
      docTypesRef.current = res.data.name_docs;
    });
  }, []);

  return (
    <CustomDialog
      title={"Subir archivos"}
      fullWidth
      maxWidth="sm"
      open={open}
      btnList={[
        { onClick: onClickUploadCancel, title: "Cancelar" },
        {
          onClick: onClickUploadFile,
          variant: "contained",
          disabled,
          title: isUserBank ? "Aceptar" : "Continuar",
        },
      ]}
      customAlertProps={
        handleDataRef.current?.params.colDef.field === "notaEncargoFirmada"
          ? {
              title:
                "Por favor cargar tu nota de encargo en formato pdf con los mismos datos de la oferta ganadora de la subasta, lista para la firma digital.",
              alertProps: { severity: "warning" },
            }
          : undefined
      }
    >
      <>
        {isFileOptionalToUpload && (
          <Typography variant="body1">
            Puedes subir un archivo (Opcional).
          </Typography>
        )}
        <Dropzone
          onDrop={(afiles) => handleOnDrop(afiles)}
          accept={acceptedFileType()}
          maxFiles={1}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div className={dropzoneStyles.UploadFilesZone} {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <img
                  src="/assets/img/sources/add_files.svg"
                  width={100}
                  alt=""
                />
                <p> Haga clic o arrastre sus archivos aquí</p>
              </div>
            </div>
          )}
        </Dropzone>

        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <CrmUploadForm
            form={form}
            setForm={setForm}
            formError={formError}
            setFormError={setFormError}
            validateForm={validateForm}
            colId={handleDataRef.current?.params.colDef.field as UploadableCol}
            isUserBank={isUserBank}
          />
          {file && (
            <Grid item xs={12}>
              <Card sx={{ width: "100%" }}>
                <CardHeader
                  avatar={
                    <Box>
                      {file.status === "loading" && (
                        <CircularProgress size={20} />
                      )}
                      {file.status === "loaded" && (
                        <HelperText text="Archivo enviado correctamente, se encuentra disponible en los Documentos de la Hipoteca">
                          <CheckCircleIcon color="primary" />
                        </HelperText>
                      )}
                      {file.status === "await" && (
                        <HelperText text="Documento listo para cargar. Seleccione el tipo de archivo correspondiente y presione el botón para subir los archivos">
                          <HistoryToggleOffIcon color="primary" />
                        </HelperText>
                      )}
                      {file.status === "error" && (
                        <HelperText text={file.error_message}>
                          <ErrorIcon color="error" />
                        </HelperText>
                      )}
                    </Box>
                  }
                  action={
                    <IconButton
                      onClick={() => {
                        setFile(null);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                  title={
                    handleDataRef.current?.params &&
                    `${
                      getFileType(
                        handleDataRef.current.params.colDef
                          .field as UploadableCol
                      )?.label
                    }`
                  }
                />
                <CardContent>
                  <Typography textAlign="center">
                    📃 Nombre del documento: {file.name}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
        </Grid>
      </>
    </CustomDialog>
  );
}
